import {
  type Palette,
  type PaletteColor,
  type PaletteOptions,
  Box,
  styled,
} from "@mui/material";

import { house } from "../History/svg";
import { leftBottomHouse } from "./svg";

type PaletteColors = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof PaletteOptions];

type ResponsiveBulma<K = string> =
  | K
  | {
      xs: K;
      md: K;
    };

const houseBannerPropsList = [
  "mainColor",
  "primary",
  "secondary",
  "sizeScale",
  "rotationLeft",
  "rotationRight",
  "positionLeft",
  "positionRight",
];

export const HouseBanner = styled(Box, {
  shouldForwardProp: (prop: string) => !houseBannerPropsList.includes(prop),
})<{
  mainColor: PaletteColors;
  primary: "light" | "dark" | "main";
  secondary: "light" | "dark" | "main";
  sizeScale: ResponsiveBulma<number>;
  rotationLeft: ResponsiveBulma<number>;
  rotationRight: ResponsiveBulma<number>;
  positionLeft: ResponsiveBulma; // expemple "0 center"
  positionRight: ResponsiveBulma; // expemple "0 center"
}>`
  z-index: 1;
  color: ${({ theme, mainColor }) => theme.palette[mainColor].contrastText};
  background-color: ${({ theme, mainColor, primary }) =>
    theme.palette[mainColor][primary]};
  position: relative;
  ::before,
  ::after {
    ${({ theme, borderRadius }) =>
      borderRadius
        ? `border-radius:${
            (borderRadius as number) * theme.shape.borderRadius
          }px;`
        : ""}
    content: "";
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    ${({ theme }) => theme.breakpoints.up("md")} {
      background-size: ${({ sizeScale }) =>
        `auto ${typeof sizeScale === "number" ? sizeScale : sizeScale.md}%`};
    }
    ${({ theme }) => theme.breakpoints.down("md")} {
      background-size: ${({ sizeScale }) =>
        `auto ${typeof sizeScale === "number" ? sizeScale : sizeScale.xs}%`};
    }
    background-repeat: no-repeat;
    box-sizing: border-box;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    ::after {
      background-image: ${({ theme, mainColor, secondary, rotationLeft }) =>
        house(
          theme.palette[mainColor][secondary],
          typeof rotationLeft === "number" ? rotationLeft : rotationLeft.md,
        )};
      background-position: ${({ positionLeft }) =>
        typeof positionLeft === "string" ? positionLeft : positionLeft.md};
    }
    ::before {
      background-image: ${({ theme, mainColor, secondary, rotationRight }) =>
        house(
          theme.palette[mainColor][secondary],
          typeof rotationRight === "number" ? rotationRight : rotationRight.md,
        )};
      background-position: ${({ positionRight }) =>
        typeof positionRight === "string" ? positionRight : positionRight.md};
    }
  }
  ${({ theme }) => theme.breakpoints.down("md")} {
    ::after {
      background-image: ${({ theme, mainColor, secondary, rotationLeft }) =>
        house(
          theme.palette[mainColor][secondary],
          typeof rotationLeft === "number" ? rotationLeft : rotationLeft.xs,
        )};
      background-position: ${({ positionLeft }) =>
        typeof positionLeft === "string" ? positionLeft : positionLeft.xs};
    }
    ::before {
      background-image: ${({ theme, mainColor, secondary, rotationRight }) =>
        house(
          theme.palette[mainColor][secondary],
          typeof rotationRight === "number" ? rotationRight : rotationRight.xs,
        )};
      background-position: ${({ positionRight }) =>
        typeof positionRight === "string" ? positionRight : positionRight.xs};
    }
  }
`;

export const CornerHouseBanner = styled(Box, {
  shouldForwardProp: (prop: string) =>
    prop !== "mainColor" && prop !== "ownership",
})<{ mainColor: PaletteColors; ownership: string }>`
  z-index: 1;
  background-color: ${({ theme, mainColor, ownership }) =>
    ownership === "owner"
      ? theme.palette[mainColor].dark
      : theme.palette[mainColor].lighter};
  position: relative;
  ::after {
    background-image: ${({ theme, mainColor }) =>
      leftBottomHouse(theme.palette[mainColor].light)};
    background-size: auto 80%;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      background-size: auto 50%;
    }
    background-repeat: no-repeat;
    box-sizing: border-box;
    background-position: 0% 0%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    content: "";
    ${({ theme }) => theme.breakpoints.down("sm")} {
      background-size: auto 30%;
      background-position: -50% -20%;
    }
  }
`;
