import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import { Icons } from "../icons";

interface IManagement {
  icon: string;
  label: string;
}

export const Management: React.FC<{
  title: string;
  subtitle: string;
  list: IManagement[];
}> = ({ title, subtitle, list }) => {
  return (
    <Container>
      <Box textAlign="center" py={{ xs: 6, md: 10 }}>
        <Typography
          variant="h2"
          component="h2"
          color="secondary"
          fontWeight={600}
          fontSize={{ xs: 22, md: 40 }}
          pb={2}
        >
          {title}
        </Typography>
        <Typography
          variant="h3"
          fontSize={16}
          color="secondary"
          pb={6}
          fontWeight={500}
        >
          {subtitle}
        </Typography>
        <Grid container rowSpacing={8}>
          {list.map(({ icon, label }, i) => {
            return (
              <Grid item key={i + icon} xs={4} md={4} px={{ xs: 2, sm: 10 }}>
                <Stack
                  height={60}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Icons width={{ xs: 40, md: 70 }} icon={icon} />
                </Stack>
                <Typography
                  variant="h4"
                  fontSize={{ xs: 12, md: 20 }}
                  color="secondary"
                  pt={3}
                  textTransform="uppercase"
                >
                  {label}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Container>
  );
};
