import { Box, Container, Stack, Button } from "@mui/material";

import { Key, KeyText } from "../Header/Logo";
import safeLink from "../safeLink";

export const HeaderLanding: React.FC<{
  link: string;
  label: string;
}> = ({ label, link }) => (
  <Box
    component="header"
    position="sticky"
    top={0}
    zIndex={100}
    bgcolor="white"
  >
    <Container>
      <Stack flexDirection="row" py={1} justifyContent="space-between">
        <Stack display={{ sm: "none" }} justifyContent="center">
          <Key />
        </Stack>
        <Stack display={{ xs: "none", sm: "flex" }} justifyContent="center">
          <KeyText />
        </Stack>
        <Button variant="contained" size="medium" {...safeLink(link)}>
          {label}
        </Button>
      </Stack>
    </Container>
  </Box>
);
