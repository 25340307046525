import cssEscape from "~/utils/cssEscape";

const leftBottomHouseSVG = (
  color: string,
) => `<svg xmlns="http://www.w3.org/2000/svg" width="431" height="384" viewBox="0 0 431 384" fill="none">
<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M-46.8488 -527.551C-75.8239 -551.963 -118.176 -551.963 -147.151 -527.551L-508.16 -223.403C-525.705 -208.622 -535.83 -186.854 -535.83 -163.918V217.067C-535.83 260.031 -500.988 294.86 -458.009 294.86H264.009C306.988 294.86 341.83 260.031 341.83 217.067V-163.918C341.83 -186.854 331.705 -208.621 314.16 -223.403L-46.8488 -527.551ZM-202.039 -592.654C-141.352 -643.782 -52.6479 -643.782 8.03864 -592.654L369.048 -288.505C405.793 -257.547 427 -211.957 427 -163.918V217.067C427 307.052 354.026 380 264.009 380H-458.009C-548.026 380 -621 307.052 -621 217.067V-163.918C-621 -211.956 -599.793 -257.547 -563.048 -288.505L-202.039 -592.654Z" stroke="${color}" stroke-width="8"/>
</svg>`;

const dataImg = (url: string) => `url("data:image/svg+xml,${cssEscape(url)}")`;

export const leftBottomHouse = (color: string) =>
  dataImg(leftBottomHouseSVG(color));
