import cssEscape from "~/utils/cssEscape";

const partialHouseSvg = (color: string) => `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="426"
  height="409"
  viewBox="0 0 426 409"
  fill="none"
>
  <path
    d="M425.922 278.741C425.531 264.955 424.127 251.291 419.711 237.926C412.582 216.558 399.334 198.143 382.493 183.469C362.364 165.842 148.509 -6.39951 126.697 -22.4006C107.162 -36.8255 84.0331 -46.5519 59.7061 -47.9984C-328 -48.0026 402.526 -47.9971 38.2939 -47.9984C13.9669 -46.5519 -9.16214 -36.8255 -28.6971 -22.4006C-50.5092 -6.39951 -264.364 165.842 -284.493 183.469C-301.334 198.143 -314.582 216.558 -321.711 237.926C-326.127 251.291 -327.53 264.955 -327.921 278.741C-327.929 288.513 -327.938 294.745 -327.946 296.274C-327.951 298.678 -327.956 301.305 -327.96 304.135C-327.943 306.596 -327.932 309.057 -327.939 311.518C-328.127 366.813 -327.869 549.748 -327.345 580.414C-327.077 596.601 -325.504 612.079 -320.541 626.966C-306.648 666.273 -271.621 695.06 -231.044 702.815C-218.459 705.229 -205.826 705.05 -193.113 705.11C-179.4 705.11 -165.677 705.17 -151.884 705.17C351.217 706.277 -253.217 706.277 249.885 705.17C263.677 705.17 277.4 705.11 291.113 705.11C303.826 705.05 316.459 705.229 329.044 702.815C369.62 695.06 404.648 666.273 418.541 626.966C423.504 612.079 425.077 596.601 425.345 580.414C425.869 549.748 426.127 366.813 425.939 311.518C425.932 309.057 425.943 306.596 425.96 304.135C425.956 301.305 425.951 298.678 425.946 296.274C425.938 294.745 425.93 288.513 425.922 278.741Z"
    fill="${color}"
  />
</svg>`;

const houseSvg = (color: string, rotate = 1) => `
<svg xmlns="http://www.w3.org/2000/svg" width="754" height="755" viewBox="0 0 754 755" fill="none" style="transform: rotate(${rotate}deg)">
  <g style="mix-blend-mode:multiply" opacity="0.4">
    <path 
      d="M753.922 327.174C753.531 313.37 752.127 299.688 747.711 286.306C740.582 264.909 727.334 246.47 710.493 231.776C690.364 214.125 476.509 41.6557 454.697 25.6333C435.162 11.1893 412.033 1.45004 387.706 0.00163472C0 -0.00260629 730.526 0.00289251 366.294 0.00163472C341.967 1.45004 318.838 11.1893 299.303 25.6333C277.491 41.6557 63.6365 214.125 43.5075 231.776C26.6657 246.47 13.418 264.909 6.28917 286.306C1.8731 299.688 0.469623 313.37 0.0785875 327.174C0.0705682 336.959 0.0623156 343.199 0.0539175 344.73C0.0491827 347.137 0.0444293 349.769 0.039697 352.602C0.0572911 355.066 0.0682382 357.531 0.0613647 359.994C-0.126757 415.363 0.130673 598.541 0.655432 629.247C0.923074 645.456 2.49604 660.955 7.4592 675.862C21.3518 715.22 56.3795 744.045 96.9562 751.811C109.541 754.228 122.174 754.049 134.887 754.109C148.6 754.109 162.323 754.169 176.116 754.169C679.217 755.277 74.7834 755.277 577.885 754.169C591.677 754.169 605.4 754.109 619.113 754.109C631.826 754.049 644.459 754.228 657.044 751.811C697.62 744.045 732.648 715.22 746.541 675.862C751.504 660.955 753.077 645.456 753.345 629.247C753.869 598.541 754.127 415.363 753.939 359.994C753.932 357.531 753.943 355.066 753.96 352.602C753.956 349.769 753.951 347.137 753.946 344.73C753.938 343.199 753.93 336.959 753.922 327.174Z"
      fill="${color}"
    />
  </g>
</svg>
`;

const dataImg = (url: string) => `url("data:image/svg+xml,${cssEscape(url)}")`;

export const partialHouse = (color: string) => dataImg(partialHouseSvg(color));

export const house = (color: string, rotate?: number) =>
  dataImg(houseSvg(color, rotate));
