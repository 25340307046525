import { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Container,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export interface IArticles {
  subtitle: string;
  content: string;
}

export const Answers: React.FC<{
  title: string;
  articles: IArticles[];
  pt?: boolean;
}> = ({ title, articles, pt = false }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (answer: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? answer : false);
    };
  return (
    <Box
      component="section"
      pb={{ xs: 6, md: 10 }}
      pt={pt ? { xs: 6, md: 10 } : 0}
    >
      <Container>
        <Stack direction={{ md: "row" }} justifyContent="space-between">
          <Box width={{ md: "45%" }}>
            <Typography
              variant="h2"
              fontSize={{ xs: 24, md: 40 }}
              color={"secondary"}
              whiteSpace={{ md: "pre-line" }}
              textAlign={{ xs: "center", md: "left" }}
              fontWeight={600}
            >
              {title}
            </Typography>
          </Box>
          <Box width={{ md: "55%" }}>
            {articles.map((article, index) => (
              <Accordion
                key={article.subtitle}
                expanded={expanded === `answer${index}`}
                onChange={handleChange(`answer${index}`)}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="h3"
                    color={"secondary"}
                    py={2}
                    fontWeight={600}
                    fontSize={18}
                    pr={1}
                  >
                    {article.subtitle}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    color={"secondary"}
                    fontWeight={400}
                  >
                    {article.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
