import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import { Icons } from "../icons";
import safeLink from "../safeLink";

export const End: React.FC<{
  title: string;
  img: {
    src: string;
    alt: string;
  };
  list: string[];
  cta: {
    link: string;
    label: string;
  };
  color: string;
  ownership: string;
}> = ({ title, img, list, cta, color, ownership }) => {
  return (
    <Box pb={4}>
      <Box
        component="section"
        bgcolor={ownership === "owner" ? `${color}.lighter` : "white"}
      >
        <Container>
          <Stack
            justifyContent="space-between"
            flexDirection="row"
            flexWrap="wrap"
            borderTop={ownership === "owner" ? 0 : 1}
            borderBottom={ownership === "owner" ? 1 : 0}
            borderColor={"#CBDDF1"}
            pt={4}
            pb={{ xs: 5, md: 4 }}
            rowGap={4}
            minHeight="80px"
          >
            <Stack
              justifyContent="center"
              width={{ xs: "100%", md: "auto" }}
              pt={2}
            >
              <Typography
                textAlign={{ xs: "center", md: "start" }}
                color="secondary"
                variant="body2"
                fontWeight={600}
                mb={{ xs: 0, md: 1 }}
              >
                AUSSI VU DANS
              </Typography>
            </Stack>
            {list.map((icon, i) => (
              <Icons
                key={icon + i}
                width={{ xs: "30%", md: "auto" }}
                alignItems="center"
                justifyContent="center"
                icon={icon}
                mColor="secondary"
              />
            ))}
          </Stack>
        </Container>
      </Box>
      <Box
        component="section"
        bgcolor={`${color}.lighter`}
        sx={(theme) => ({
          borderBottomLeftRadius: theme.shape.borderRadius * 15,
          borderBottomRightRadius: theme.shape.borderRadius * 15,
        })}
      >
        <Container>
          <Grid container>
            <Grid item xs={6} py={{ xs: 14, md: 13 }}>
              <Typography
                color="secondary"
                variant="h3"
                component="h2"
                fontSize={{ xs: 20, md: 35 }}
                whiteSpace={{ xs: "normal", md: "pre-line" }}
                fontWeight={600}
                pr={{ xs: 2, md: 0 }}
                pl={{ xs: 1, md: 0 }}
              >
                {title}
              </Typography>
              <Box pl={{ xs: 1, md: 0 }} pt={3}>
                <Button
                  {...safeLink(cta.link)}
                  variant="contained"
                  size="large"
                >
                  {cta.label}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={6} mb={5}>
              <Box
                width="100%"
                height="100%"
                position="relative"
                overflow="hidden"
                sx={(theme) => ({
                  backgroundImage: img,
                  borderBottomLeftRadius: theme.shape.borderRadius * 15,
                  borderBottomRightRadius: theme.shape.borderRadius * 15,
                  [theme.breakpoints.down("sm")]: {
                    borderBottomLeftRadius: theme.shape.borderRadius * 22,
                    borderBottomRightRadius: theme.shape.borderRadius * 22,
                  },
                })}
              >
                <Box
                  component="img"
                  src={img.src}
                  alt={img.alt}
                  position="absolute"
                  right={0}
                  top={0}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
