import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Container,
  Divider,
  Box,
  Link as MuiLink,
  Typography,
  Stack,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { Key, KeyText } from "../Header/Logo";
import { type LinkProperties } from "../links";

export const FooterLanding: React.FC = () => (
  <Container component="footer">
    <Box display={{ md: "none" }}>
      <KeyText />
    </Box>
    <Box display={{ xs: "none", md: "initial" }}>
      <Key />
    </Box>
    <Box pb={4} pt={1}>
      <Typography color="secondary" fontSize={14}>
        La 1ère agence immobilière qui paye votre loyer&nbsp;!
        <br />
        La sous-location légale c'est possible.
      </Typography>
    </Box>
    <Divider light />
    <Stack
      direction={{ xs: "column-reverse", md: "row" }}
      justifyContent={{ md: "space-between" }}
      alignItems="center"
      py={4}
    >
      <Typography color="secondary" fontSize={14}>
        © {new Date().getFullYear()}, Leazly. Tous droits reservés. &nbsp;
      </Typography>
      <Stack direction="row">
        {social.map((props, index) => (
          <SocialIcon key={index} {...props} />
        ))}
      </Stack>
    </Stack>
  </Container>
);

const SocialIcon: React.FC<LinkProperties> = ({ href, label, target }) => (
  <MuiLink href={href} target={target}>
    <IconButton>{label}</IconButton>
  </MuiLink>
);
const social: LinkProperties[] = [
  {
    href: "https://www.youtube.com/channel/",
    label: <YouTubeIcon color="secondary" sx={{ opacity: 0.5 }} />,
    target: "_blank",
  },
  {
    href: "https://www.facebook.com/Leazly",
    label: <FacebookIcon color="secondary" sx={{ opacity: 0.5 }} />,
    target: "_blank",
  },
  {
    href: "https://www.linkedin.com/company/100429107",
    label: <LinkedInIcon color="secondary" sx={{ opacity: 0.5 }} />,
    target: "_blank",
  },
  {
    href: "https://twitter.com/",
    label: <TwitterIcon color="secondary" sx={{ opacity: 0.5 }} />,
    target: "_blank",
  },
  {
    href: "https://www.instagram.com/leazly__",
    label: <InstagramIcon color="secondary" sx={{ opacity: 0.5 }} />,
    target: "_blank",
  },
];
