import { Box, Container, Stack, Typography } from "@mui/material";

import { LandingColors } from "../HeroBanner/Common/svg";
import { Icons } from "../icons";

export interface IQualities {
  icon: string;
  label: string;
  text: string;
}

export const Qualities: React.FC<{
  color?: LandingColors; // to remove
  title: string;
  list: IQualities[];
}> = ({ title, list }) => {
  return (
    <Box component="section" py={{ xs: 6, md: 10 }}>
      <Container>
        <Typography
          variant="h2"
          color="secondary"
          textAlign="center"
          fontWeight={600}
          fontSize={{ xs: 24, md: 40 }}
        >
          {title}
        </Typography>
        <Stack
          pt={6}
          flexDirection={{ xs: "column-reverse", md: "row" }}
          gap={4}
        >
          {list.map(({ icon, label, text }, i) => {
            return (
              <Stack
                key={i + label}
                alignItems="center"
                flexGrow={1}
                flexBasis={0}
              >
                <Icons icon={icon} mColor="primary" sColor="secondary" />
                <Typography
                  component="h3"
                  py={{ xs: 2, md: 2 }}
                  color="secondary"
                  fontWeight={600}
                  fontSize={28}
                >
                  {label}
                </Typography>
                <Typography
                  variant="body2"
                  color="secondary"
                  textAlign="justify"
                  fontWeight={400}
                  px={4}
                >
                  {text}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Container>
    </Box>
  );
};
