import { Box, Container, Grid, Stack, Typography } from "@mui/material";

import { Icons } from "../icons";
import type { ColorOptions, PaletteColors } from "../icons/index.type";
import { CornerHouseBanner } from "./styled";

export interface IInsured {
  icon: string;
  title: string;
  text: string;
}

export const Insured: React.FC<{
  title: string;
  subtitle: string;
  ownership: string;
  mColor: PaletteColors;
  sColorOption?: ColorOptions;
  titleColorOption: ColorOptions;
  subtitleColorOption?: ColorOptions;
  textColorOption?: ColorOptions;
  iconColorOption?: ColorOptions;
  list: IInsured[];
  background?: boolean;
}> = ({
  title,
  list,
  ownership,
  mColor,
  titleColorOption,
  subtitleColorOption,
  textColorOption,
  iconColorOption,
  subtitle,
  background,
}) => {
  return (
    <Box
      mt={background && ownership === "tenant" ? { xs: 6, md: 10 } : {}}
      bgcolor={
        background && ownership === "tenant" ? "landingTenant.light" : ""
      }
    >
      <Container>
        <Box py={{ xs: 6, md: 10 }}>
          <CornerHouseBanner
            mainColor={mColor}
            ownership={ownership}
            pt={8}
            pb={5}
            px={{ xs: 2, md: 6 }}
            borderRadius={10}
          >
            <Grid container>
              <Grid item xs={12} sm={3} md={5}>
                <Typography
                  variant="h2"
                  component="h3"
                  color={`${mColor}.${titleColorOption}`}
                  whiteSpace="pre-line"
                  textAlign={{ xs: "center", sm: "start" }}
                  position="relative"
                  top={{ xs: -20, md: 10 }}
                  left={{ md: 40 }}
                  fontWeight={600}
                >
                  {title} {""}
                  <Typography
                    component="span"
                    variant="h2"
                    color={`${mColor}.${subtitleColorOption}`}
                  >
                    {subtitle}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={7}>
                {list.map(({ icon, text, title }, i) => {
                  return (
                    <Stack flexDirection="row" key={i + "insured"}>
                      <Box pr={{ md: 3 }} mr={{ xs: 1, md: 0 }}>
                        <Icons
                          pt={{ xs: 0.7, md: 0.25 }}
                          width={{ xs: 15, md: 20 }}
                          height="auto"
                          icon={icon}
                          mColor={mColor}
                          mColorOption={iconColorOption}
                        />
                      </Box>
                      <Box pb={2} pt={{ xs: 0.5, md: 0 }} pr={{ md: 10 }}>
                        <Typography
                          variant="h4"
                          fontSize={{ xs: 18, md: 24 }}
                          fontWeight={600}
                          pb={1}
                          color={
                            textColorOption
                              ? `${mColor}.${textColorOption}`
                              : "white"
                          }
                        >
                          {title}
                        </Typography>
                        <Typography
                          variant="body1"
                          textAlign="justify"
                          pb={2}
                          pr={{ xs: 2, md: 0 }}
                          fontSize={{ xs: 14, md: 16 }}
                          color={
                            textColorOption
                              ? `${mColor}.${textColorOption}`
                              : "white"
                          }
                        >
                          {text}
                        </Typography>
                      </Box>
                    </Stack>
                  );
                })}
              </Grid>
            </Grid>
          </CornerHouseBanner>
        </Box>
      </Container>
    </Box>
  );
};
