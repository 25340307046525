import { Container, Stack, Typography } from "@mui/material";

import { HouseBanner } from "./styled";

export interface INumber {
  number: string;
  label: string;
}

export const Numbers: React.FC<{
  numbers: INumber[];
  pb?: boolean;
}> = ({ numbers, pb = false }) => {
  return (
    <Container component="section" sx={{ pb: pb ? { xs: 6, md: 10 } : 0 }}>
      <HouseBanner
        py={{ xs: 0, md: 12 }}
        borderRadius={10}
        mainColor="green"
        sizeScale={{ xs: 150, md: 150 }}
        rotationLeft={{ xs: 115, md: 160 }}
        rotationRight={{ xs: -65, md: -160 }}
        primary="dark"
        secondary="main"
        positionLeft={{ xs: "30% 225%", md: "-5% center" }}
        positionRight={{ xs: "70% -125%", md: "105% center" }}
      >
        <Stack flexDirection={{ xs: "column", md: "row" }}>
          {numbers.map(({ label, number }, i) => {
            return (
              <Stack
                key={i + "numbers"}
                alignItems="center"
                flexGrow={1}
                flexBasis={0}
                py={{ xs: 4, md: 0 }}
              >
                <Typography
                  variant="h1"
                  component="span"
                  fontWeight={600}
                  fontSize={{ md: 64 }}
                >
                  {number}
                </Typography>
                <Typography
                  pt={{ xs: 0, md: 1 }}
                  fontWeight={500}
                  fontSize={20}
                >
                  {label}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </HouseBanner>
    </Container>
  );
};
