import { Box, Container, Stack, Typography } from "@mui/material";

import { LandingColors } from "../HeroBanner/Common/svg";

export const Legal: React.FC<{
  color: LandingColors;
  title: string;
  text: string;
  image: {
    src: string;
    alt: string;
  };
}> = ({ color, title, text, image }) => {
  return (
    <Box
      component="section"
      pt={{ xs: 0, sm: 10 }}
      display={{ xs: "none", sm: "initial" }}
    >
      <Container>
        <Stack
          direction={"row"}
          borderRadius={{ xs: 8, md: 10 }}
          bgcolor={(theme) => theme.palette.background[color]}
          overflow="hidden"
        >
          <Box p={{ xs: 3, md: 10 }}>
            <Typography
              variant="h4"
              component="h2"
              fontSize={{ md: 32 }}
              fontWeight={600}
              color="secondary"
              pb={2}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color={"secondary.dark"}
              pt={5}
              display={{ xs: "none", md: "block" }}
              whiteSpace="pre-line"
            >
              {text}
            </Typography>
          </Box>
          <Box
            display={{ xs: "none", md: "block" }}
            component="img"
            src={image.src}
            alt={image.alt}
          />
        </Stack>
      </Container>
    </Box>
  );
};
