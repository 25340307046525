import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import { LandingColors } from "../HeroBanner/Common/svg";
import { Icons } from "../icons";
import { type PaletteColors } from "../icons/index.type";
import safeLink from "../safeLink";

export interface IStep {
  title: string;
  text: string;
  color: PaletteColors;
}

export const Steps: React.FC<{
  color: LandingColors;
  title: string;
  list: IStep[];
  cta: {
    link: string;
    label: string;
  };
  image: {
    src: string;
    alt: string;
  };
}> = ({ color, title, cta, image, list }) => {
  return (
    <Box
      bgcolor={(theme) => theme.palette[color].lighter}
      py={{ xs: 6, md: 10 }}
    >
      <Container>
        <Grid container>
          <Grid
            item
            md={6}
            display={{ xs: "none", md: "grid" }}
            pr={3}
            pt={3}
            alignItems="center"
          >
            <Box
              component="img"
              src={image.src}
              alt={image.alt}
              width="90%"
              sx={{ objectFit: "contain" }}
              mb={{ md: 3 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              ml={{ xs: 2, md: -1 }}
              mr={{ xs: 2, md: 0 }}
              variant="h2"
              color="secondary"
              fontWeight={600}
              fontSize={{ xs: 24, md: 40 }}
              pb={6}
              textAlign={{ xs: "center", md: "initial" }}
            >
              {title}
            </Typography>
            {list.map(({ title, text, color }, i) => {
              return (
                <Grid container key={i + "step"} pb={3} pr={{ xs: 3, md: 0 }}>
                  <Grid item xs={2}>
                    <Stack alignItems="center" ml={{ xs: -1, md: 0 }}>
                      <Typography
                        component="span"
                        variant="h3"
                        color={`${color}.main`}
                      >
                        {i + 1}
                      </Typography>
                      <Box
                        pt={{ xs: 3, sm: 2 }}
                        pl={i % 2 === 0 ? 1 : 0}
                        pr={i % 2 === 0 ? 0 : 1}
                      >
                        {i + 1 < list.length && (
                          <Icons
                            icon={
                              i % 2 === 0
                                ? "rightCurvedArrowDown"
                                : "leftCurvedArrowDown"
                            }
                            mColor={color}
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      pt={{ xs: 0, sm: 1 }}
                      variant="h4"
                      component="h3"
                      fontSize={{ xs: 18, md: 24 }}
                      fontWeight={600}
                      color="secondary"
                    >
                      {title}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: 14, md: 16 }}
                      lineHeight={1.3}
                      pt={1}
                      color="secondary"
                      textAlign="justify"
                      fontWeight={400}
                    >
                      {text}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
            <Grid container>
              <Grid item xs={0} md={2}></Grid>
              <Grid
                item
                xs={12}
                md={10}
                display={{ xs: "flex", md: "initial" }}
                justifyContent="center"
                pt={3}
              >
                <Button
                  variant="contained"
                  size="large"
                  {...safeLink(cta.link)}
                >
                  {cta.label}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
