import { useState, useRef, useEffect } from "react";

import { Box, Container, Stack, Typography } from "@mui/material";

import { Icons } from "../icons";

export interface IComment {
  img: string;
  stars: number;
  name: string;
  date: string;
  text: string;
}

function sideScroll(
  element: HTMLDivElement | null,
  direction: string,
  speed: number | undefined,
  distance: number,
  step: number,
) {
  if (!element) return;
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    const inc = scrollAmount + step > distance ? distance - scrollAmount : step;
    if (direction == "left") {
      element.scrollLeft -= inc;
    } else {
      element.scrollLeft += inc;
    }
    scrollAmount += inc;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

export const Carousel: React.FC<{
  list: IComment[];
  title: string;
  ownership: string;
  pt?: boolean;
}> = ({ list, title, ownership, pt }) => {
  const [disableScroll, setDisableScroll] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const container = e.currentTarget;

    const elementWidth = getElementWidth();
    const clonesWidth = elementWidth * list.length;
    const scrollPos = container.scrollLeft;
    let scrollPosAdd;
    container.clientWidth > clonesWidth
      ? (scrollPosAdd = container.clientWidth)
      : (scrollPosAdd = clonesWidth);

    if (!disableScroll) {
      if (scrollPos > scrollPosAdd + elementWidth) {
        setScroll(container, Math.floor(scrollPos - scrollPosAdd));
        setDisableScroll(true);
      } else if (scrollPos <= elementWidth) {
        setScroll(container, Math.floor(scrollPos + scrollPosAdd));
        setDisableScroll(true);
      }
    }
  };

  const getElementWidth = () => {
    const container = scrollContainerRef.current;
    if (!container) return 0;

    const childs = container.children;
    return (
      childs[1].getBoundingClientRect().right -
      childs[0].getBoundingClientRect().right
    );
  };

  const setScroll = (element: HTMLDivElement, pos: number) =>
    (element.scrollLeft = pos);

  const scrollNext = () =>
    sideScroll(scrollContainerRef.current, "right", 10, getElementWidth(), 10);

  const scrollPrev = () =>
    sideScroll(scrollContainerRef.current, "left", 10, getElementWidth(), 10);

  useEffect(() => {
    if (disableScroll) {
      const timeout = setTimeout(() => {
        setDisableScroll(false);
      }, 40);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [disableScroll]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    const width = getElementWidth();

    if (container) setScroll(container, width);
  }, []);

  return (
    <Box
      bgcolor={ownership === "tenant" ? "landingTenant.light" : ""}
      pt={pt ? { xs: 6, md: 10 } : 0}
    >
      <Container>
        <Stack>
          <Typography
            color="secondary"
            variant="h2"
            component="span"
            textAlign="center"
            fontSize={{ xs: 24, md: 40 }}
            fontWeight={600}
            pb={6}
          >
            {title}
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          pb={6}
          overflow="scroll"
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
              width: "0 !important",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          onScroll={handleScroll}
          ref={scrollContainerRef}
        >
          {[...list, ...list].map(({ date, img, name, stars, text }, i) => {
            return (
              <Box
                key={"card" + i}
                flexShrink={0}
                width="300px"
                border={2}
                borderColor={ownership === "tenant" ? "white" : "blue.light"}
                borderRadius={3}
                p={3}
                mr={4}
                bgcolor="white"
                height="fit-content"
              >
                <Stack flexDirection="row" alignItems="center">
                  <Stack
                    component={Typography}
                    color="white"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius={50}
                    height={50}
                    width={50}
                    bgcolor="blue.main"
                  >
                    {img ? (
                      <Box component="img" src={img} />
                    ) : (
                      <Typography component="span" variant="h2">
                        {name[0]}
                      </Typography>
                    )}
                  </Stack>
                  <Box pl={1}>
                    <Typography
                      component="span"
                      color="secondary"
                      variant="subtitle2"
                    >
                      {name}
                    </Typography>
                    <Typography color="secondary" variant="body2">
                      {date}
                    </Typography>
                  </Box>
                </Stack>
                <Stack flexDirection="row">
                  {[...Array(stars).keys()].map((s, i) => {
                    return (
                      <Icons
                        my={2}
                        mr={0.5}
                        key={"stars" + i}
                        icon="star"
                        width={15}
                        height={15}
                        mColor="yellow"
                      />
                    );
                  })}
                </Stack>
                <Typography
                  variant="body2"
                  color="secondary"
                  textAlign="justify"
                  fontWeight={400}
                >
                  {text}
                </Typography>
              </Box>
            );
          })}
        </Stack>
        <Stack
          justifyContent="center"
          flexDirection="row"
          pb={{ xs: 6, md: 10 }}
        >
          <Icons
            icon="arrowLeft"
            onClick={scrollPrev}
            borderRadius={25}
            bgcolor={ownership === "tenant" ? "white" : "blue.light"}
            mColor="secondary"
            p={1}
            mr={3}
            sx={{ cursor: "pointer" }}
            height={40}
            width={40}
          />
          <Icons
            icon="arrowRight"
            onClick={scrollNext}
            borderRadius={25}
            bgcolor={ownership === "tenant" ? "white" : "blue.light"}
            mColor="secondary"
            p={1}
            height={40}
            width={40}
            sx={{ cursor: "pointer" }}
          />
        </Stack>
      </Container>
    </Box>
  );
};
